<template>
	<v-container fluid class="site-index site-width" :class="computedClass">
		<div class="d-flex align-center justify-center">
			<h1 class="site-index__title">Content Index</h1>
			<v-btn
				icon color="#54C1B2" class="ml-2"
				:disabled="dataLoading || lettersCountLoading"
				@click="reloadData"
			>
				<v-icon>fas fa-sync-alt</v-icon>
			</v-btn>
		</div>

		<AlphabetTabs
			:value="letter"
			:letters-count="lettersCount"
			:optional="categoryMode"
			class="flex-grow-0 mt-6"
            @input="updateLetter"
		/>

		<IndexSearchResults
			:letter="letter"
			:all-items="items"
			:filtered-items="filteredItems"
			:category-mode="categoryMode"
			:loading="dataLoading"
            :search="search"
            :options="options"
            :total-server-items="totalServerItems"
            :categories="categories"
            :all-categories="allCategories"
			class="mt-8"
			@load-category="loadCategory"
            @update-search="search = $event"
            @update-options="options = $event"
            @update-categories="categories = $event"
		/>
	</v-container>
</template>

<script>
import { debounce } from 'vue-debounce'

import AlphabetTabs from '../components/AlphabetTabs.vue'
import IndexSearchResults from '../components/IndexSearchResults.vue'
import { loadLettersCount, loadSiteIndexPages, loadSiteIndexPagesByCategory } from '../api/index'

const LETTER_ALL = 'ALL'

export default {
    components: {
		AlphabetTabs,
		IndexSearchResults,
	},
    data () {
        return {
			letter: LETTER_ALL,
			categoryMode: false,
            search: '',
			options: {
				sortBy: [],
				sortDesc: [],
				page: 1,
				itemsPerPage: 25,
			},
            categories: [],
            allCategories: [],

			items: [],
            totalServerItems: 0,
			cachedItems: {},
			dataLoading: false,
			dataError: false,

			lettersCount: [],
			lettersCountLoading: false,
			lettersCountError: false,
        }
    },
	created () {
		this.loadLettersCount()
		this.loadData()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.mdAndDown && !this.isMobileView
		},
		computedClass () {
			return [
				this.isMobileView && 'site-index--mobile',
				this.isTabletView && 'site-index--tablet',
			]
		},
		agentCode () {
			return this.user.Agent.AgentCode
		},
		filteredItems () {
			if (this.dataError) { return [] }
			if (!this.items.length) { return [] }
			if (!this.categories.length || this.letter === LETTER_ALL || this.categoryMode) { return this.items }
			return this.items.filter(({ category_id }) => this.categories.includes(category_id))
		},
    },
	watch: {
        search () {
			if (this.letter !== LETTER_ALL) { return }
            this.loadData()
        },
        options () {
			if (this.letter !== LETTER_ALL) { return }
            this.loadData()
        },
        categories (newValue, oldValue) {
			if (newValue.join(',') === oldValue.join(',')) { return }
			if (this.letter !== LETTER_ALL) { return }
            this.loadData()
        },
	},
	methods: {
		reloadData () {
			this.cachedItems = {}
			this.items = []
			this.categoryMode = false
			this.updateLetter(LETTER_ALL)
			this.loadLettersCount()
			this.loadData()
		},
		async loadCategory (categoryId) {
			this.categoryMode = true
			this.updateLetter(undefined)
			await this.loadPagesByCategory(categoryId)

            this.options = {
                ...this.options,
                sortBy: ['title'],
                sortDesc: [false],
            }
		},
		loadLettersCount: debounce(async function () {
			this.setLettersCountLoadingStatus(true, false)
			const res = await loadLettersCount()
			if (res.error) {
				this.lettersCount = []
				this.setLettersCountLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Site Index letters count.`, res.message)
			} else {
				this.lettersCount = res.data
				this.setLettersCountLoadingStatus(false, false)
			}
		}, 300),
		loadData: debounce(async function () {
			if (!this.letter) { return }
			if (this.letter === LETTER_ALL) { 
                return this.loadAllData()
            }

            this.totalServerItems = -1
            this.allCategories = []
			const cacheKey = `${this.agentCode}_${this.letter}`
			// check cache
			if (this.cachedItems[cacheKey]) {
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
				return Promise.resolve()
			}
			// call API
			this.setDataLoadingStatus(true, false)
			const res = await loadSiteIndexPages(this.letter)
			if (res.error) {
				this.items = []
				this.setDataLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Site Index data.`, res.message)
			} else {
				this.cachedItems[cacheKey] = res.data
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
			}
		}, 300),
		async loadAllData() {
			if (this.letter !== LETTER_ALL) { return }
			this.setDataLoadingStatus(true, false)

            let sortBy = this.options.sortBy
            if (sortBy.includes?.('category_name')) {
                sortBy = [...sortBy]
                sortBy.splice(sortBy.indexOf('category_name'), 1, 'category_id')
            }
            const params = {
                ...this.options,
                search: this.search,
				sortBy: sortBy.join(','),
				sortDesc: this.options.sortDesc.join(','),
                category_id: this.categories.join(','),
                needAvailableFilters: !this.allCategories.length,
            }
			const res = await loadSiteIndexPages(this.letter, params)

			if (res.error) {
				this.items = []
				this.setDataLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Site Index data.`, res.message)
			} else {
				this.items = res.data
                this.totalServerItems = res.meta?.total
                if (res.meta?.filters?.category_id) {
                    this.allCategories = res.meta.filters.category_id.slice(1).sort((a, b) => a.text > b.text ? 1 : -1)
                }
				this.setDataLoadingStatus(false, false)
			}
		},
		async loadPagesByCategory(categoryId) {
            this.totalServerItems = -1
			const cacheKey = `${this.agentCode}_category_${categoryId}`
			// check cache
			if (this.cachedItems[cacheKey]) {
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
				return Promise.resolve()
			}
			// call API
			this.setDataLoadingStatus(true, false)
			this.items = []
			const params = {
				itemsPerPage: 'all',
				sortBy: 'title',
				sortDesc: false,
				category_id: categoryId,
				status: 'publish',
				division: '-All-',
			}
			const res = await loadSiteIndexPagesByCategory(params)
			if (res.error) {
				this.items = []
				this.setDataLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Site Index data.`, res.message)
			} else {
				this.cachedItems[cacheKey] = res.data
				this.items = [...res.data]
				this.setDataLoadingStatus(false, false)
			}
		},
		setDataLoadingStatus (loading, error) {
			this.dataLoading = loading
			this.dataError = error
		},
		setLettersCountLoadingStatus (loading, error) {
			this.lettersCountLoading = loading
			this.lettersCountError = error
		},
        updateLetter(value) {
            this.letter = value

            if (!value) { return }

            this.categoryMode = false
            this.options = {
                ...this.options,
                page: 1,
            }
            this.categories = []

            this.loadData()
        }
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: site-index;

.#{$block} {
	--block-padding: 1rem 2rem;

	&--tablet {
		--block-padding: 1rem 1.5rem;
	}

	&--mobile {
		--block-padding: 0.5rem 1rem;
	}

	height: 100%;
	padding: var(--block-padding);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	color: $color-black;

	&__title {
		margin-bottom: 0;
		font-size: calc(28px + (36 - 30) * ((100vw - 320px) / (1600 - 320)));
		font-weight: bold !important;
		letter-spacing: normal;
	}
}
</style>
