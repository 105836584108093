<template>
	<v-card outlined min-width="100%" class="site-index-results" :class="computedClass">
		<v-progress-linear v-if="loading" indeterminate color="#54C1B2" class="site-index-results__progress" />

		<div class="site-index-results__content">
			<div class="site-index-results__options">
				<v-select
					:items="categoryOptions"
					v-model="computedCategories"
					multiple
					hide-details
					clearable
					:disabled="categoryMode"
					:menu-props="{ contentClass: 'site-index-results__category-select' }"
					label="Category"
					color="#54C1B2"
					class="site-index-results__options-select"
				/>

				<v-text-field
					v-model="computedSearch"
					:disabled="isDemoMode"
					append-icon="icon-q-search"
					label="Search"
					hide-details
					clearable
					color="#54C1B2"
					class="site-index-results__options-search"
				/>
			</div>

			<IndexSearchResultsTable
				:items="computedItems"
				:letter="letter"
				:search="computedSearch"
				:options="computedOptions"
                :total-server-items="totalServerItems"
				class="mt-10"
				@update-options="computedOptions = $event"
			>
				<template #item.title="{ item, value }">
					<div class="results-item results-item__link" @click="loadPage(item)">
						{{ value }}
						<v-icon v-if="item.isExternal" class="ml-1">fas fa-external-link-alt</v-icon>
					</div>
				</template>
				<template #item.category_name="{ item, value }">
					<div v-if="!value" class="results-item">{{ '- NA -' }}</div>
					<div v-else-if="categoryMode" class="results-item">{{ value }}</div>
					<div v-else class="results-item results-item__link" @click="loadCategory(item)">{{ value }}</div>
				</template>
			</IndexSearchResultsTable>
		</div>
	</v-card>
</template>

<script>
import IndexSearchResultsTable from './IndexSearchResultsTable.vue'
import { config } from '../config/siteIndexConfigs'

const defaultOptions = {
	sortBy: [],
	sortDesc: [],
	page: 1,
	itemsPerPage: 25,
}

const ORIGIN = 'https://hq.quility.com'

export default {
	components: { IndexSearchResultsTable },
	props: {
		letter: { type: String },
		allItems: { type: Array, default: () => [] },
		filteredItems: { type: Array, default: () => [] },
		loading: { type: Boolean, default: false },
		categoryMode: { type: Boolean, default: false },
		search: { type: String, default: '' },
		categories: { type: Array, default: () => [] },
		totalServerItems: { type: Number, default: -1 },
        allCategories: { type: Array, default: () => [] },
		options: {
			type: Object,
			default: () => ({ ...defaultOptions }),
		},
	},
	data () {
		return {
			config,
		}
	},
	created () {
		this.updateOptions()
	},
	computed: {
		computedSearch: {
			get () { return this.search },
			set (v) { this.$emit('update-search', v) },
		},
		computedOptions: {
			get () { return this.options },
			set (v) { this.$emit('update-options', v) },
		},
		computedCategories: {
			get () { return this.categories },
			set (v) { this.$emit('update-categories', v) },
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedClass () {
			return [
				this.isMobileView && 'site-index-results--mobile',
			]
		},
		categoryOptions () {
			if (this.allCategories.length) { return this.allCategories }
			if (!this.allItems.length) { return [] }
			const categories = this.allItems.map(({ category_id, category_name }) => ({
                text: category_name || '- NA -',
                value: category_id,
            })).sort((a, b) => a.text > b.text ? 1 : -1)
			return [...new Set(categories)]
		},
		defaultSortOptions () {
			const item = this.config.find(({ sortBy }) => sortBy)
			if (!item) {
				return {
					sortBy: [],
                	sortDesc: [],
				}
			}
			return  {
				sortBy: [item.key],
                sortDesc: typeof item.sortDesc === 'boolean' ? [item.sortDesc] : [],
			}
		},
		computedItems () {
			if (!this.filteredItems.length) { return [] }

			return this.filteredItems.map((item) => {
				const { external_url } = item
				const isExternal = Boolean(
					external_url &&
					external_url.trim?.().toLowerCase() !== 'internal' &&
					external_url.trim?.().toLowerCase() !== '\'internal\'' &&
					!external_url.startsWith(ORIGIN) &&
					!external_url.startsWith('/')
				)
				return { ...item, isExternal }
			})
		},
	},
	watch: {
		categoryMode (curr) {
			if (curr) { return }

            this.computedOptions = {
                ...this.computedOptions,
                sortBy: this.defaultSortOptions.sortBy,
                sortDesc: this.defaultSortOptions.sortDesc,
            }
		},
	},
	methods: {
		loadCategory ({ category_id }) {
			if (!category_id) { return }
			this.$emit('load-category', category_id)
		},
		loadPage (item) {
			const { external_url, slug } = item
            if (
				external_url &&
				external_url.trim?.().toLowerCase() !== 'internal' &&
				external_url.trim?.().toLowerCase() !== '\'internal\'' // TODO!!!
			) {
				if (external_url.startsWith(ORIGIN)) {
					const route = external_url.replace(ORIGIN, '')
					this.$router.push(route)
				} else if (external_url.startsWith('/')) {
					this.$router.push(external_url)
				} else {
					window.open(external_url, '_blank')
				}
            } else {
                this.$router.push('/page/' + slug)
            }
		},
		updateOptions () {
            this.computedOptions = {
                ...this.computedOptions,
				...this.defaultSortOptions,
            }
		},
	},
}
</script>

<style lang="scss">
@import '../style/style.scss';

.site-index-results__category-select {
	$scrollbar-width-chrome: 6px;

	.primary--text {
		color: $color-accent !important;
	}

	&::-webkit-scrollbar {
		width: $scrollbar-width-chrome;
		height: $scrollbar-width-chrome;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 10px $color-dark;
		border-radius: 2 * $scrollbar-width-chrome;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color-accent;
		border-radius: 0.5 * $scrollbar-width-chrome;
	}

	&::-webkit-scrollbar-corner {
		background: transparent;
	}

	scrollbar-color: $color-accent white;
	scrollbar-width: thin;
}
</style>

<style lang="scss" scoped>
@import '../style/style.scss';

.site-index-results {
	--padding-size: 2rem;
	
	&--mobile {
		--padding-size: 1rem;
	}

	&:not(.v-sheet--tile):not(.v-card--shaped) {
		border-radius: $border-radius-xs;
	}

	&__progress {
		position: absolute;
		width: 100%;
	}

	::v-deep &__content {
		border-radius: inherit;
		padding: var(--padding-size);
	}

	&__options {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		gap: 2rem 5rem;
	}

	&__options-search {
		padding-top: 0 !important;
		max-width: 600px;
		flex: 1 1 240px;
	}

	&__options-select {
		padding-top: 0 !important;
		max-width: 600px;
		flex: 1 1 240px;
	}

	.results-item {
		padding: 0 0.2rem;
		display: inline-block;

		.v-icon.v-icon {
			font-size: 1em !important;
			color: currentColor;
		}

		&__link {
			cursor: pointer;
	
			&:hover {
				color: $color-accent;
				text-decoration: underline;
			}
		}
	}
}
</style>
