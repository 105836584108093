<template>
	<v-tabs
		:key="resizeKey"
		v-model="computedValue"
		background-color="transparent"
		show-arrows
		hide-slider
		:optional="optional"
		centered
		color="#54C1B2"
		class="site-index-alphabet-tabs"
		:class="computedClass"
	>
		<v-tab
			v-for="(item) in computedTabs"
			:key="item.title"
			:tab-value="item.value"
			:disabled="item.disabled"
            :class="{ 'tabs__item--long': item.value.length > 1 }"
			class="tabs__item"
		>
			{{ item.title }}
		</v-tab>
	</v-tabs>
</template>

<script>
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')

const allTabKey = 'ALL'
const allTab = {
	value: allTabKey,
	title: allTabKey,
	disabled: false,
}

const defaultTabs = alphabet.map((letter) => ({
	value: letter,
	title: letter,
	disabled: false,
}))

export default {
	props: {
		value: { type: String },
		lettersCount: { type: Array, default: () => [] },
		tabs: { type: Array, default: () => [allTab, ...defaultTabs] },
		optional: { type: Boolean, default: false },
	},
	data () {
		return {
			resizeKey: 0,
		}
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedValue: {
			get () {
				const index = this.tabs.findIndex((item) => item?.value === this.value)
				return index
			},
			set (v) {
				const letter = this.tabs[v]?.value
				this.$emit('input', letter)
			},
		},
		computedTabs () {
			if (!this.lettersCount?.length) { return this.tabs }
			return this.tabs.map(item => ( item.value === allTabKey ? item : {
				...item,
				disabled: !this.lettersCount.some(({ letter, count }) => item.value === letter.toUpperCase() && !!count)
			}))
		},
		computedClass () {
			return [
				this.isMobileView && 'site-index-alphabet-tabs--mobile',
			]
		},
	},
	watch: {
		isMobileView () {
			this.resizeKey += 1
		},
    },
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.site-index-alphabet-tabs {
	--size: 3rem;
	--font-size: 1.25rem;
	--font-size-arrow: 1.75rem;
	--margin-size: 0.625rem;
	
	&--mobile {
		--size: 2.5rem;
		--font-size: 1rem;
		--font-size-arrow: 1.5rem;
		--margin-size: 0.25rem;
	}

	.tabs__item.v-tab {
		min-width: unset;
		width: var(--size);
		height: var(--size);
		margin: 0 var(--margin-size);
		border-radius: 50%;
		border: 1px solid $color-accent;
		font-size: var(--font-size);
		font-weight: 600;
		line-height: 1;
		letter-spacing: normal;
		white-space: normal;

        &.tabs__item--long {
            width: auto;
            border-radius: 2rem;

            &::before {
                border-radius: 2rem;
            }
        }

		&::before {
			border-radius: 50%;
			background-color: $color-accent-dark;
		}

		&:hover {
			color: $color-white !important;
			
			&::before {
				z-index: -1;
				opacity: 1;
				border: 1px solid $color-accent;
			}
		}

		&:not(.v-tab--active) {
			color: $color-accent;
		}
	}

	.tabs__item.v-tab--active {
		background-color: $color-accent-dark;
		border-color: $color-accent-dark;
		color: $color-white;
	}

	.tabs__item.v-tab--disabled {
		background-color: $color-disabled;

		&:not(.v-tab--active) {
			border-color: $color-disabled;
			color: $color-white;
		}
	}

	::v-deep {
		.v-slide-group__prev,
		.v-slide-group__next {
			min-width: unset;
			width: var(--size);
			height: var(--size);
			flex-basis: var(--size);
			flex-shrink: 0;
	
			.v-icon {
				font-size: var(--font-size-arrow) !important;
				color: $color-accent;
			}
		}
	}
}
</style>
