import moment from 'moment'

export const serverDateFormat = 'YYYY-MM-DD HH:mm:ss'
export const dateFormat = 'MM/DD/YY'
const dateSort = (a, b) => moment(a, serverDateFormat).isAfter(b, serverDateFormat) ? -1 : 1

// additional options:
// - for one of rows:	sortBy: true (initial sort)
// - for any row:		visible: false (the column will be shown in the expandable row on all screens)
// - for any row:		mobileVisible: true (the column will be displayed on the mobile screen)
export const config = [
	{ label: 'Page', key: 'title', mobileVisible: true },
	{ label: 'Category', key: 'category_name' },
	{ label: 'Modified\u00A0On', key: 'updated_at', type: 'date', format: serverDateFormat, toFormat: dateFormat, filterable: false, sortBy: true, sortDesc: false, sort: dateSort },
]
